import React, {useContext, useEffect, useState} from 'react'
import {SessionContext} from '../../contexts/session-context/session-context'
import './plan-message-display.scss'
import {interpolateMessage} from '../../util/message-interpolation'

const PATHS_FOR_MESSAGES = [
  'overview',
  'contribution',
  'distribution',
  'investment',
  'statement',
  'beneficiaries',
  'dashboard',
  'plan-overview',
  'planHistory',
  'personal-details',
  'plan-info',
  'contributions/summary',
  'balances-by-source',
  'distributions/summary',
  'investments/summary',
  'manage',
  'transfer',
  'invDirection',
  'investment-allocation',
  'research'
];

export default function PlanMessageDisplay(props) {
  const {planMessages, planId, empId, contractId} = useContext(
    SessionContext
  )
  const {pathName: locationPath} = props
  const [pathName, setPathName] = useState('')
  const [filteredPlanMessages, setFilteredPlanMessages] = useState([])

  useEffect(() => {
    function checkUserData() {
      let pathName;
      const directPath = locationPath
      const onClientParticipantSite = directPath
        .toLowerCase()
        .includes('nqparticipant')
      if (onClientParticipantSite) {
        pathName = directPath
      } else {
        pathName = localStorage.getItem('pathName')
      }
      if (pathName) {
        setPathName(pathName)
      }
    }

    checkUserData()
    window.addEventListener('storage', checkUserData)

    return () => {
      window.removeEventListener('storage', checkUserData)
    }
  }, [locationPath]);

  useEffect(() => {
    setFilteredPlanMessages(filterPlanMessages(pathName, planMessages));
  }, [empId, planId, contractId, pathName, planMessages]);

  return (
    <div>
      {filteredPlanMessages && filteredPlanMessages.length > 0 && (
        <div className="alert theme-banner theme-banner-info util-margin-bottom-0">
          <section className={'container no-print'}>
            {filteredPlanMessages.map((planMessage, index) =>
              displayMessage(planMessage, index, contractId, planId, empId)
            )}
          </section>
        </div>
      )}
    </div>
  );
}

export function filterPlanMessages(selectedPath, planMessages) {
  // This is used to include the invDirection page with investment plan messages which is a contribution page but needs to count for investment pages
  const hasInvestmentPage = planMessages.some((planMessage) => {
    return planMessage.messagePageTarget.includes('investment');
  });
  const messagePaths = PATHS_FOR_MESSAGES.filter((path) => {
    return selectedPath.includes(path) || (selectedPath.includes('invDirection') && hasInvestmentPage && path.includes('invDirection'));
  });
  if (planMessages) {
    return planMessages.filter((planMessage) => {
      const showOnPage = messagePaths.includes(
        planMessage.messagePageTarget
      )
      const showOnFutureAllocations = messagePaths.includes('invDirection') && (planMessage.messagePageTarget === 'investment');
      const showEverywhere =
        planMessage.messagePageTarget === 'all' ||
        planMessage.messageName === 'GLOBAL'
      return showOnPage || showEverywhere || showOnFutureAllocations
    })
  } else {
    return []
  }
}

function displayMessage(planMessage, index, contractId, planId, empId) {
  let message = planMessage.subHeader
  message = interpolateMessage(message, contractId, planId, empId)
  return (
    <div key={index} className="plan-message" data-testid="message">
      <h4>{planMessage.header}</h4>
      <div
        data-testid={'message-' + index}
        key={'planMessage' + index}
        dangerouslySetInnerHTML={{__html: message}}
      ></div>
      <hr className="separator"/>
    </div>
  )
}

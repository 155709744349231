import React from 'react';
import { Link } from 'react-router-dom';
import { shouldRenderNewParticipantLink } from '../../util/should-render-new-participant-link';
export default function PlanNavigationMenuItem(props) {
  const { id, name, displayText, target, navigationLink } = props;

  return (
    <ParticipantLink
      id={id}
      navigationLink={navigationLink}
      name={name}
      target={target}
      displayText={displayText}
    />
  );
}

function ParticipantLink(props) {
  const { id, navigationLink, name, target, displayText } = props;

  if (shouldRenderNewParticipantLink(navigationLink)) {
    const url = new URL(navigationLink);

    return (
      <li role='presentation' id={id}>
        <Link
          to={
            removeNqParticipant(url.pathname, '/nq-participant', '') +
            url.search
          }
          id={`Sub-menu:_${id}`}
          data-testid={`dropdown-item-${id}`}
          name={name}
        >
          {displayText}
        </Link>
      </li>
    );
  }

  return (
    <li role='presentation' id={id}>
      <a
        href={navigationLink}
        id={`Sub-menu:_${id}`}
        data-testid={`dropdown-item-${id}`}
        name={name}
        target={target}
      >
        {displayText}
      </a>
    </li>
  );
}

export function removeNqParticipant(pathname) {
  return pathname.replace('/nq-participant', '');
}

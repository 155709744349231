import { html, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { Instance, createPopper } from '@popperjs/core';
import { ifDefined } from 'lit/directives/if-defined.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import styles from './action-menu.scss?inline';
import '@principal/design-system-icons-web/more-horizontal';
import '@principal/design-system-icons-web/chevron-down';
import { PdsElement } from '../PdsElement';
// eslint-disable-next-line import/no-duplicates
import { PdsButton, ButtonVariant } from '../button/button';
// eslint-disable-next-line import/no-duplicates
import '../button/button';
import '../action-menu-item/action-menu-item';

/**
 * @summary This component provides a set of menu items to the user when the action-menu button is clicked
 *
 * @slot default Provides a set of menu items in the list and also helps to slot in content and icon in pds-button
 *
 * @fires pds-button-click A custom event dispatched on click
 */
@customElement('pds-action-menu', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsActionMenu extends PdsElement {
  /**
   * Style variant
   * - **default** renders the standard variant, with light-colored action menu.
   * - **inverted** renders the inverted variant, with dark-colored action menu.
   */
  @property()
  variant: 'default' | 'inverted' = 'default';

  /**
   * - **primary** renders the action-menu when used as a normal component
   * - **secondary** renders the action-menu when used as a the recipe
   */
  @property()
  triggerVariant: 'primary' | 'secondary' = 'primary';

  /**
   * - **default** renders the button used for the most common calls to action that don't require as much visual attention.
   * - **default-inverted** renders a default button for use on darker backgrounds.
   * - **primary** renders the button used for the most important calls to action.
   * - **primary-inverted** renders a primary button for use on darker backgrounds.
   * - **icon** renders the button used for icon.
   * - **icon-inverted** renders the button for icons used on darker backgrounds.
   */
  @property()
  buttonVariant: ButtonVariant;

  /**
   * Small button
   */
  @property()
  buttonSize: 'sm' | 'default' = 'default';

  /**
   * Removes a separator in the action menu item list
   */
  @property({ type: Boolean })
  hideSeparator: boolean = false;

  /**
   * Adds a aria-label to the action menu item
   */
  @property()
  ariaLabel: string;

  /**
   * Adds a label to action menu list
   */
  @property()
  label: string;

  /**
   * This is used to open and close the action menu list
   */
  @property({ type: Boolean, reflect: true })
  open: boolean = false;

  /**
   * This grabs the pds-button element
   * @internal
   */
  @query('.pds-c-action-menu__button')
  popperButton: PdsButton;

  /**
   * This grabs the div element of action menu list
   * @internal
   */
  @query('.pds-c-action-menu__list')
  popperPopup: HTMLElement;

  /**
   * This grabs the div element of arrow
   * @internal
   */
  @query('.pds-c-action-menu__arrow')
  popperArrow: HTMLElement;

  private popperInstance: Instance;

  constructor() {
    super();
    this.handleOnClickOutsideActionMenuList =
      this.handleOnClickOutsideActionMenuList.bind(this);
    this.hidePopper = this.hidePopper.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener(
      'mouseup',
      this.handleOnClickOutsideActionMenuList,
      false,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(
      'mouseup',
      this.handleOnClickOutsideActionMenuList,
      false,
    );
  }

  createInstance() {
    this.popperInstance = createPopper(this.popperButton, this.popperPopup, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: ({ placement }: { placement: string }) => {
              if (placement === 'top' || placement === 'bottom') {
                return [0, 28];
              }
              return [];
            },
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'bottom'],
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 12, // 12px from the edges of the popper
          },
        },
      ],
    });
  }

  destroyInstance() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }

  showPopper() {
    this.open = true;
    this.popperButton.setAttribute('isActive', 'true');
    this.popperPopup.setAttribute('show-popper', '');
    this.popperArrow.setAttribute('data-popper-arrow', '');
    this.popperArrow.setAttribute('aria-expanded', 'true');
    this.createInstance();
    this.popperPopup.addEventListener(
      'pds-action-menu-item-click',
      this.hidePopper,
      false,
    );
  }

  hidePopper() {
    this.open = false;
    this.popperButton.removeAttribute('isActive');
    this.popperPopup.removeAttribute('show-popper');
    this.popperArrow.removeAttribute('data-popper-arrow');
    this.popperArrow.setAttribute('aria-expanded', 'false');
    this.destroyInstance();
    this.popperPopup.removeEventListener(
      'pds-action-menu-item-click',
      this.hidePopper,
      false,
    );
  }

  handleClick() {
    if (this.popperPopup.hasAttribute('show-popper')) {
      this.hidePopper();
    } else {
      this.showPopper();
    }
  }

  handleOnClickOutsideActionMenuList(e: MouseEvent) {
    // If the action menu list is already closed then we don't care about outside clicks and we
    // can bail early
    if (!this.popperPopup.hasAttribute('show-popper')) {
      return;
    }

    // If clicking the action menu button again, bail here and let the toggle function take over
    if (this.popperButton && e.composedPath().includes(this.popperButton)) {
      return;
    }

    let didClickInside = false;

    // Check to see if we clicked inside the active action menu item
    if (this.popperPopup) {
      didClickInside = e.composedPath().includes(this.popperPopup);
    }

    // If the action menu list is active and we've clicked outside of the list then it should be closed.
    if (this.popperPopup.hasAttribute('show-popper') && !didClickInside) {
      this.hidePopper();
    }
  }

  renderButton() {
    if (this.triggerVariant === 'secondary') {
      return html`<pds-button
        type="button"
        variant="${this.buttonVariant === 'default'
          ? nothing
          : this.buttonVariant}"
        size="${this.buttonSize}"
        class="${this.classEl('button')}"
        ariaExpanded="${this.open}"
        ariaLabel="This button opens a action menu list"
        isActive="${this.open ? 'true' : nothing}"
        @click=${this.handleClick}
        ><slot name="button-content"></slot
        ><slot name="icon"
          ><pds-icon-chevron-down size="sm" class="${this.classEl('icon')}">
          </pds-icon-chevron-down></slot
      ></pds-button>`;
    }

    return html`<pds-button
      variant="${this.buttonVariant === 'default'
        ? nothing
        : this.buttonVariant}"
      type="button"
      size="${this.buttonSize}"
      class="${this.classEl('button')}"
      ariaExpanded="${this.open}"
      isActive="${this.open ? 'true' : nothing}"
      ariaLabel="This button opens a action menu list"
      @click=${this.handleClick}
      ><slot name="button-content"
        ><pds-icon-more-horizontal></pds-icon-more-horizontal></slot
    ></pds-button>`;
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  render() {
    return html`<div class="${this.getClass()}">
      <section class="${this.classEl('section')}">
        ${this.renderButton()}
      </section>
      <div
        class="${this.classEl('list')}"
        aria-label=${ifDefined(this.ariaLabel)}
      >
        ${this.label
          ? html`<span class="pds-actionMenu-header">${this.label}</span>`
          : nothing}
        <ul>
          <slot></slot>
          ${!this.hideSeparator
            ? html`<li
                class="${this.classEl('separator')}"
                role="separator"
              ></li>`
            : nothing}
          <slot name="footer"></slot>
        </ul>
        <div class="${this.classEl('arrow')}"></div>
      </div>
    </div>`;
  }
}

/**
 * Do not edit directly
 * Generated on Wed, 04 Oct 2023 20:48:17 GMT
 */

export const SemanticBackgroundDefault = "#ffffff";
export const SemanticBackgroundSubtle = "#f6f6f8";
export const SemanticBackgroundStrong = "#333333";
export const SemanticBackgroundDisabled = "#f6f6f8";
export const SemanticBackgroundHighlight = "#dcf94d";
export const SemanticBackgroundBrandDefault = "#e3f5fd";
export const SemanticBackgroundBrandStrong = "#0076cf";
export const SemanticBackgroundBrandXstrong = "#002855";
export const SemanticBackgroundBrandGradientSubtle = "linear-gradient(60deg, #d2f1fd 100%, #F0FBFE 100%)";
export const SemanticBackgroundBrandGradientStrong = "linear-gradient(60deg, #004c97 25%, #0076cf 100%)";
export const SemanticBackgroundBrandGradientXstrong = "linear-gradient(60deg, #002855 25%, #004c97 100%)";
export const SemanticBackgroundOpacityNone = "#33333300";
export const SemanticBackgroundOpacityDim = "#333333e6";
export const SemanticBackgroundOpacityBrand = "#002855e6";
export const SemanticBackgroundOpacityBrandGradientStrong = "linear-gradient(60deg, #004c97e6 25%, #0076cfe6 100%)";
export const SemanticBackgroundInteractiveDefault = "#0061a0";
export const SemanticBackgroundInteractiveStrong = "#004887";
export const SemanticBackgroundInteractiveXstrong = "#002e6d";
export const SemanticBackgroundInteractiveNeutralStrong = "#f6f6f8";
export const SemanticBackgroundInteractiveNeutralXstrong = "#e8e8e8";
export const SemanticBackgroundInteractiveInvertedStrong = "#d2f1fd";
export const SemanticBackgroundInteractiveInvertedXstrong = "#bbe6fc";
export const SemanticBackgroundInteractiveInvertedNeutralStrong = "#00000033";
export const SemanticBackgroundInteractiveInvertedNeutralXstrong = "#0000004D";
export const SemanticBorderSubtle = "#e8e8e8";
export const SemanticBorderDefault = "#c5c6c5";
export const SemanticBorderStrong = "#616267";
export const SemanticBorderInvertedDefault = "#ffffff";
export const SemanticBorderDisabled = "#c5c6c5";
export const SemanticBorderInfo = "#0076cf";
export const SemanticBorderSuccess = "#00a29b";
export const SemanticBorderWarning = "#f76800";
export const SemanticBorderError = "#c00000";
export const SemanticBorderInteractiveDefault = "#0061a0";
export const SemanticBorderInteractiveStrong = "#004887";
export const SemanticBorderInteractiveXstrong = "#002e6d";
export const SemanticBorderInteractiveInvertedStrong = "#d2f1fd";
export const SemanticBorderInteractiveInvertedXstrong = "#bbe6fc";
export const SemanticBorderIconDefault = "#333333";
export const SemanticBorderOpacityNone = "#33333300";
export const SemanticTextDefault = "#333333";
export const SemanticTextSubtle = "#616267";
export const SemanticTextStrong = "#333333";
export const SemanticTextDisabled = "#9d9ea4";
export const SemanticTextError = "#c00000";
export const SemanticTextInteractiveDefault = "#0061a0";
export const SemanticTextInteractiveStrong = "#004887";
export const SemanticTextInteractiveXstrong = "#002e6d";
export const SemanticTextInteractiveInvertedDefault = "#ffffff";
export const SemanticTextInteractiveInvertedStrong = "#d2f1fd";
export const SemanticTextInteractiveInvertedXstrong = "#bbe6fc";
export const SemanticTextInvertedDefault = "#ffffff";
export const SemanticTextInvertedSubtle = "#62b5e5";
export const SemanticTextInvertedStrong = "#55fff5";
export const ComponentButtonDisabledBackground = "#f6f6f8";
export const ComponentButtonDisabledText = "#9d9ea4";
export const ComponentButtonDisabledBorder = "#f6f6f8";
export const ComponentButtonPrimaryBackground = "#0061a0";
export const ComponentButtonPrimaryText = "#ffffff";
export const ComponentButtonPrimaryBorder = "#0061a0";
export const ComponentButtonPrimaryHoverBackground = "#004887";
export const ComponentButtonPrimaryHoverText = "#ffffff";
export const ComponentButtonPrimaryHoverBorder = "#004887";
export const ComponentButtonPrimaryActiveBackground = "#002e6d";
export const ComponentButtonPrimaryActiveText = "#ffffff";
export const ComponentButtonPrimaryActiveBorder = "#002e6d";
export const ComponentButtonPrimaryInvertedBackground = "#94d8fa";
export const ComponentButtonPrimaryInvertedText = "#002855";
export const ComponentButtonPrimaryInvertedBorder = "#94d8fa";
export const ComponentButtonPrimaryInvertedHoverBackground = "#7bbfe1";
export const ComponentButtonPrimaryInvertedHoverText = "#000f3c";
export const ComponentButtonPrimaryInvertedHoverBorder = "#7bbfe1";
export const ComponentButtonPrimaryInvertedActiveBackground = "#61a5c7";
export const ComponentButtonPrimaryInvertedActiveText = "#000022";
export const ComponentButtonPrimaryInvertedActiveBorder = "#61a5c7";
export const ComponentButtonDefaultBackground = "#ffffff";
export const ComponentButtonDefaultText = "#0061a0";
export const ComponentButtonDefaultBorder = "#0061a0";
export const ComponentButtonDefaultHoverBackground = "#f6f6f8";
export const ComponentButtonDefaultHoverText = "#004887";
export const ComponentButtonDefaultHoverBorder = "#004887";
export const ComponentButtonDefaultActiveBackground = "#e8e8e8";
export const ComponentButtonDefaultActiveText = "#002e6d";
export const ComponentButtonDefaultActiveBorder = "#002e6d";
export const ComponentButtonDefaultInvertedBackground = "#33333300";
export const ComponentButtonDefaultInvertedText = "#ffffff";
export const ComponentButtonDefaultInvertedBorder = "#ffffff";
export const ComponentButtonDefaultInvertedHoverBackground = "#00000033";
export const ComponentButtonDefaultInvertedHoverText = "#ffffff";
export const ComponentButtonDefaultInvertedHoverBorder = "#ffffff";
export const ComponentButtonDefaultInvertedActiveBackground = "#0000004D";
export const ComponentButtonDefaultInvertedActiveText = "#ffffff";
export const ComponentButtonDefaultInvertedActiveBorder = "#ffffff";
export const ComponentButtonLinkBackground = "#33333300";
export const ComponentButtonLinkBorder = "#33333300";
export const ComponentButtonLinkText = "#0061a0";
export const ComponentButtonLinkHoverText = "#002e6d";
export const ComponentButtonLinkActiveText = "#002e6d";
export const ComponentButtonLinkInvertedBackground = "#33333300";
export const ComponentButtonLinkInvertedBorder = "#33333300";
export const ComponentButtonLinkInvertedText = "#ffffff";
export const ComponentButtonLinkInvertedHoverText = "#bbe6fc";
export const ComponentButtonLinkInvertedActiveText = "#bbe6fc";
export const ComponentButtonIconBackground = "#33333300";
export const ComponentButtonIconBorder = "#33333300";
export const ComponentButtonIconIconStroke = "#0061a0";
export const ComponentButtonIconHoverBackground = "#0000001A";
export const ComponentButtonIconHoverBorder = "#33333300";
export const ComponentButtonIconHoverIconStroke = "#004887";
export const ComponentButtonIconActiveBackground = "#00000033";
export const ComponentButtonIconActiveBorder = "#33333300";
export const ComponentButtonIconActiveIconStroke = "#002e6d";
export const ComponentButtonIconDisabledBackground = "#ffffff";
export const ComponentButtonIconDisabledBorder = "#33333300";
export const ComponentButtonIconDisabledIconStroke = "#c5c6c5";
export const ComponentButtonIconInvertedBackground = "#33333300";
export const ComponentButtonIconInvertedBorder = "#33333300";
export const ComponentButtonIconInvertedIconStroke = "#ffffff";
export const ComponentButtonIconInvertedHoverBackground = "#00000033";
export const ComponentButtonIconInvertedHoverBorder = "#33333300";
export const ComponentButtonIconInvertedHoverIconStroke = "#ffffff";
export const ComponentButtonIconInvertedActiveBackground = "#0000004D";
export const ComponentButtonIconInvertedActiveBorder = "#33333300";
export const ComponentButtonIconInvertedActiveIconStroke = "#ffffff";
export const ComponentLayoutContainerMaxWidthDefault = "85.375rem";
export const ComponentLayoutContainerMaxWidthNarrow = "64rem";
export const ComponentPrimaryNavDefaultNavItemBackground = "#33333300";
export const ComponentPrimaryNavDefaultNavItemText = "#616267";
export const ComponentPrimaryNavDefaultNavItemIcon = "#616267";
export const ComponentPrimaryNavDefaultNavItemHoverText = "#004887";
export const ComponentPrimaryNavDefaultNavItemHoverBackground = "#f6f6f8";
export const ComponentPrimaryNavDefaultNavItemHoverIcon = "#004887";
export const ComponentPrimaryNavDefaultNavItemActiveText = "#002e6d";
export const ComponentPrimaryNavDefaultNavItemActiveBackground = "#e8e8e8";
export const ComponentPrimaryNavDefaultNavItemActiveIcon = "#002e6d";
export const ComponentPrimaryNavDefaultNavItemDivider = "#c5c6c5";
export const ComponentPrimaryNavDefaultNotificationItemBackground = "#33333300";
export const ComponentPrimaryNavDefaultNotificationItemText = "#333333";
export const ComponentPrimaryNavDefaultNotificationItemHoverBackground = "#f6f6f8";
export const ComponentPrimaryNavDefaultNotificationItemHoverText = "#333333";
export const ComponentPrimaryNavDefaultNotificationBadgeBackground = "#ffffff";
export const ComponentPrimaryNavDefaultNotificationBadgeBorder = "#0061a0";
export const ComponentPrimaryNavDefaultNotificationBadgeText = "#0061a0";
export const ComponentPrimaryNavDefaultDropdownBackground = "#ffffff";
export const ComponentPrimaryNavDefaultDropdownLink = "#333333";
export const ComponentPrimaryNavDefaultDropdownLabel = "#333333";
export const ComponentPrimaryNavDefaultDropdownHoverLink = "#002e6d";
export const ComponentPrimaryNavDefaultDropdownActiveLink = "#002e6d";
export const ComponentPrimaryNavDefaultBackground = "#ffffff";
export const ComponentPrimaryNavDefaultMobileMenuBackground = "#ffffff";
export const ComponentPrimaryNavDefaultMobileNotificationItemBackground = "#33333300";
export const ComponentPrimaryNavDefaultMobileNotificationItemText = "#333333";
export const ComponentPrimaryNavDefaultMobileNotificationItemHoverBackground = "#f6f6f8";
export const ComponentPrimaryNavDefaultMobileNotificationItemHoverText = "#333333";
export const ComponentPrimaryNavDefaultMobileNotificationBadgeBackground = "#ffffff";
export const ComponentPrimaryNavDefaultMobileNotificationBadgeBorder = "#0061a0";
export const ComponentPrimaryNavDefaultMobileNotificationBadgeText = "#0061a0";
export const ComponentPrimaryNavDefaultMobileLabel = "#333333";
export const ComponentPrimaryNavDefaultMobileLink = "#0061a0";
export const ComponentPrimaryNavDefaultMobileHoverLink = "#002e6d";
export const ComponentPrimaryNavDefaultMobileActiveLink = "#002e6d";
export const ComponentPrimaryNavDefaultMobileSubmenuBackground = "#0061a0";
export const ComponentPrimaryNavDefaultMobileSubmenuIcon = "#ffffff";
export const ComponentPrimaryNavDefaultMobileSubmenuLabel = "#ffffff";
export const ComponentPrimaryNavDefaultMobileSubmenuHoverBackground = "#004887";
export const ComponentPrimaryNavDefaultMobileSubmenuHoverIcon = "#ffffff";
export const ComponentPrimaryNavDefaultMobileSubmenuHoverLabel = "#ffffff";
export const ComponentPrimaryNavDefaultMobileSubmenuActiveBackground = "#002e6d";
export const ComponentPrimaryNavDefaultMobileSubmenuActiveLabel = "#ffffff";
export const ComponentPrimaryNavDefaultMobileSubmenuActiveIcon = "#ffffff";
export const ComponentPrimaryNavInvertedBackground = "#0061a0";
export const ComponentPrimaryNavInvertedNavItemBackground = "#33333300";
export const ComponentPrimaryNavInvertedNavItemText = "#ffffff";
export const ComponentPrimaryNavInvertedNavItemIcon = "#ffffff";
export const ComponentPrimaryNavInvertedNavItemHoverText = "#ffffff";
export const ComponentPrimaryNavInvertedNavItemHoverIcon = "#ffffff";
export const ComponentPrimaryNavInvertedNavItemHoverBackground = "#00000033";
export const ComponentPrimaryNavInvertedNavItemActiveText = "#ffffff";
export const ComponentPrimaryNavInvertedNavItemActiveIcon = "#ffffff";
export const ComponentPrimaryNavInvertedNavItemActiveBackground = "#0000004D";
export const ComponentPrimaryNavInvertedNavItemDivider = "#ffffff";
export const ComponentPrimaryNavInvertedNotificationItemBackground = "#33333300";
export const ComponentPrimaryNavInvertedNotificationItemText = "#333333";
export const ComponentPrimaryNavInvertedNotificationItemHoverBackground = "#f6f6f8";
export const ComponentPrimaryNavInvertedNotificationItemHoverText = "#333333";
export const ComponentPrimaryNavInvertedNotificationBadgeBackground = "#ffffff";
export const ComponentPrimaryNavInvertedNotificationBadgeBorder = "#0061a0";
export const ComponentPrimaryNavInvertedNotificationBadgeText = "#0061a0";
export const ComponentPrimaryNavInvertedDropdownBackground = "#ffffff";
export const ComponentPrimaryNavInvertedDropdownLink = "#333333";
export const ComponentPrimaryNavInvertedDropdownLabel = "#333333";
export const ComponentPrimaryNavInvertedDropdownHoverLink = "#002e6d";
export const ComponentPrimaryNavInvertedDropdownActiveLink = "#002e6d";
export const ComponentPrimaryNavInvertedMobileMenuBackground = "#002855";
export const ComponentPrimaryNavInvertedMobileNotificationItemBackground = "#33333300";
export const ComponentPrimaryNavInvertedMobileNotificationItemText = "#ffffff";
export const ComponentPrimaryNavInvertedMobileNotificationItemHoverBackground = "#00000033";
export const ComponentPrimaryNavInvertedMobileNotificationItemHoverText = "#ffffff";
export const ComponentPrimaryNavInvertedMobileNotificationBadgeBackground = "#002855";
export const ComponentPrimaryNavInvertedMobileNotificationBadgeBorder = "#ffffff";
export const ComponentPrimaryNavInvertedMobileNotificationBadgeText = "#ffffff";
export const ComponentPrimaryNavInvertedMobileDecorator = "#ffffff";
export const ComponentPrimaryNavInvertedMobileLabel = "#ffffff";
export const ComponentPrimaryNavInvertedMobileLink = "#ffffff";
export const ComponentPrimaryNavInvertedMobileHoverLink = "#bbe6fc";
export const ComponentPrimaryNavInvertedMobileActiveLink = "#bbe6fc";
export const ComponentPrimaryNavInvertedMobileSubmenuBackground = "#94d8fa";
export const ComponentPrimaryNavInvertedMobileSubmenuIcon = "#002855";
export const ComponentPrimaryNavInvertedMobileSubmenuLabel = "#002855";
export const ComponentPrimaryNavInvertedMobileSubmenuHoverBackground = "#7bbfe1";
export const ComponentPrimaryNavInvertedMobileSubmenuHoverIcon = "#000f3c";
export const ComponentPrimaryNavInvertedMobileSubmenuHoverLabel = "#000f3c";
export const ComponentPrimaryNavInvertedMobileSubmenuActiveBackground = "#61a5c7";
export const ComponentPrimaryNavInvertedMobileSubmenuActiveLabel = "#000022";
export const ComponentPrimaryNavInvertedMobileSubmenuActiveIcon = "#000022";
export const ComponentRadioDisabledForeground = "#9d9ea4";
export const ComponentSkeletonLoaderDefaultOverlay = "linear-gradient(90deg, #FFFFFF00, #FFFFFFB3, #FFFFFF00)";
export const ComponentSkeletonLoaderDefaultBackground = "#e8e8e8";
export const ComponentSkeletonLoaderInvertedBackground = "#e3f5fd26";
export const ComponentTableShadowScrollLeft = "linear-gradient(to left, #c5c6c500 0%, #c5c6c5ff 100%)";
export const ComponentTableShadowScrollRight = "linear-gradient(to right, #c5c6c500 0%, #c5c6c5ff 100%)";
export const ComponentTableShadowScrollDown = "linear-gradient(to bottom, #c5c6c500 0%, #c5c6c5ff 100%)";
export const ComponentTableShadowScrollUp = "linear-gradient(to top, #c5c6c500 0%, #c5c6c5ff 100%)";
export const ComponentTagDefaultBackground = "#e3f5fd";
export const ComponentTagDefaultText = "#333333";
export const ComponentTagDefaultHoverBackground = "#CADCE4";
export const ComponentTagDefaultActiveBackground = "#B0C2CA";
export const BreakpointsPixelXs = 320;
export const BreakpointsPixelSm = 540;
export const BreakpointsPixelMd = 768;
export const BreakpointsPixelLg = 1024;
export const BreakpointsPixelXl = 1366;
export const BaseColorBluePrincipal = "#0076cf";
export const BaseColorBluePrincipal110 = "#005db6";
export const BaseColorBluePrincipal120 = "#00439c";
export const BaseColorBlueAdmiral = "#0061a0";
export const BaseColorBlueAdmiral110 = "#004887";
export const BaseColorBlueAdmiral120 = "#002e6d";
export const BaseColorBlueTrue = "#0091da";
export const BaseColorBlueNavy = "#004c97";
export const BaseColorBlueSky01 = "#f0fbfe";
export const BaseColorBlueSky02 = "#e3f5fd";
export const BaseColorBlueSky02110 = "#CADCE4";
export const BaseColorBlueSky02120 = "#B0C2CA";
export const BaseColorBlueSky03 = "#d2f1fd";
export const BaseColorBlueSky03110 = "#B9D8E4";
export const BaseColorBlueSky03120 = "#9FBECA";
export const BaseColorBlueSky04 = "#bbe6fc";
export const BaseColorBluePrussianDefault = "#003865";
export const BaseColorBluePrussianDark = "#002855";
export const BaseColorBluePrussianDark80 = "#335b88";
export const BaseColorBluePrussianDark90 = "#1a426f";
export const BaseColorBluePrussianDark110 = "#000f3c";
export const BaseColorBluePrussianDark120 = "#000022";
export const BaseColorBlueMayaDefault = "#94d8fa";
export const BaseColorBlueMayaDefault110 = "#7bbfe1";
export const BaseColorBlueMayaDefault120 = "#61a5c7";
export const BaseColorBlueMayaDark = "#62b5e5";
export const BaseColorYellowChartreuse = "#dcf94d";
export const BaseColorGreen = "#00a29b";
export const BaseColorOrange = "#f76800";
export const BaseColorRed = "#c00000";
export const BaseColorCyanPrincipal = "#00c4d9";
export const BaseColorCyanBright = "#55fff5";
export const BaseColorCyanOceanDefault = "#007fa3";
export const BaseColorCyanOceanDark = "#315470";
export const BaseColorNeutralWhite = "#ffffff";
export const BaseColorNeutralGray01 = "#333333";
export const BaseColorNeutralGray02 = "#616267";
export const BaseColorNeutralGray03 = "#9d9ea4";
export const BaseColorNeutralGray04 = "#c5c6c5";
export const BaseColorNeutralGray05 = "#e8e8e8";
export const BaseColorNeutralGray06 = "#f6f6f8";
export const BaseColorNeutralGray07 = "#fbfbfb";
export const BaseColorGradientBlueSky03Sky01 = "linear-gradient(60deg, #d2f1fd 100%, #F0FBFE 100%)";
export const BaseColorGradientBlueNavyPrincipal = "linear-gradient(60deg, #004c97 25%, #0076cf 100%)";
export const BaseColorGradientBlueDarkPrussianNavy = "linear-gradient(60deg, #002855 25%, #004c97 100%)";
export const BaseColorOpacityBlack10 = "#0000001A";
export const BaseColorOpacityBlack20 = "#00000033";
export const BaseColorOpacityBlack30 = "#0000004D";
export const BaseColorOpacityGray0120 = "#33333333";
export const BaseColorOpacityGray0150 = "#33333380";
export const BaseColorOpacityGray0190 = "#333333e6";
export const BaseColorOpacityGray0100 = "#33333300";
export const BaseColorOpacityGray04100 = "#c5c6c5ff";
export const BaseColorOpacityGray0400 = "#c5c6c500";
export const BaseColorOpacitySky0215 = "#e3f5fd26";
export const BaseColorOpacityGradientBluePrincipal90 = "linear-gradient(60deg, #004c97e6 25%, #0076cfe6 100%)";
export const BaseColorOpacityGradientWhite = "linear-gradient(90deg, #FFFFFF00, #FFFFFFB3, #FFFFFF00)";
export const BaseColorOpacityWhite25 = "#ffffff40";
export const BaseColorOpacityWhite35 = "#ffffff59";
export const BaseColorOpacityMayaDark15 = "#62b5e526";
export const BaseColorOpacityCyanOceanDark15 = "#31547026";
export const BaseColorOpacityPrussianDark90 = "#002855e6";
export const BaseColorFdhGreenOceanLight = "#e4ffff";
export const BaseColorFdhGreenOceanDefault = "#048282";
export const BaseColorFdhGreenOceanDark = "#016464";
export const BaseColorFdhGreenPersian = "#004d52";
export const BaseColorFdhGreenOpacityDarkGradient90 = "linear-gradient(60deg, #004d52 90%, #016464 90%)";
export const BaseColorFdhGreenOpacityOceanDark90 = "linear-gradient(60deg, #016464 90%)";
export const BaseColorFdhGreenOpacityOceanLight15 = "linear-gradient(60deg, #e4ffff 15%)";
export const BaseColorFdhGreenGradientOceanLight = "linear-gradient(60deg, #048282 100%, #e4ffff 100%)";
export const BaseColorFdhGreenGradientOceanDefault = "linear-gradient(60deg, #004d52 100%, #048282 100%)";
export const BaseColorFdhGreenGradientOceanDark = "linear-gradient(60deg, #004d52 100%, #016464 100%)";
export const BaseUnit = "8px";
export const BaseTypeRoot = "16px";
export const BaseTypeFontSize12 = "0.75rem";
export const BaseTypeFontSize14 = "0.875rem";
export const BaseTypeFontSize16 = "1rem";
export const BaseTypeFontSize18 = "1.125rem";
export const BaseTypeFontSize20 = "1.25rem";
export const BaseTypeFontSize22 = "1.375rem";
export const BaseTypeFontSize24 = "1.5rem";
export const BaseTypeFontSize26 = "1.625rem";
export const BaseTypeFontSize28 = "1.75rem";
export const BaseTypeFontSize30 = "1.875rem";
export const BaseTypeFontSize32 = "2rem";
export const BaseTypeFontSize40 = "2.5rem";
export const BaseTypeFontSize48 = "3rem";
export const BaseTypeFontSize56 = "3.5rem";
export const BaseTypeFontSize64 = "4rem";
export const BaseTypeFontSize66 = "4.125rem";
export const BaseTypeFontSize74 = "4.625rem";
export const BaseTypeLineHeight14 = "0.875rem";
export const BaseTypeLineHeight16 = "1rem";
export const BaseTypeLineHeight18 = "1.125rem";
export const BaseTypeLineHeight20 = "1.25rem";
export const BaseTypeLineHeight22 = "1.375rem";
export const BaseTypeLineHeight24 = "1.5rem";
export const BaseTypeLineHeight26 = "1.625rem";
export const BaseTypeLineHeight28 = "1.75rem";
export const BaseTypeLineHeight32 = "2rem";
export const BaseTypeLineHeight34 = "2.125rem";
export const BaseTypeLineHeight36 = "2.25rem";
export const BaseTypeLineHeight40 = "2.5rem";
export const BaseTypeLineHeight42 = "2.625rem";
export const BaseTypeLineHeight48 = "3rem";
export const BaseTypeLineHeight50 = "3.125rem";
export const BaseTypeLineHeight54 = "3.375rem";
export const BaseTypeLineHeight64 = "4rem";
export const BaseTypeLineHeight68 = "4.25rem";
export const BaseTypeLineHeight72 = "5rem";
export const BaseTypeLineHeight76 = "4.75rem";
export const BaseTypeFontFamily100 = "'FS Elliot Web Regular', 'Arial', arial, sans-serif";
export const BaseTypeFontFamily200 = "'Cormorant Infant Italic', 'Times New Roman', serif";
export const BaseTypeFontFamily300 = "'Work Sans Regular', 'Arial', arial, sans-serif";
export const BaseTypeFontFamily100Light = "'FS Elliot Pro Light', 'FS Elliot Web Regular', 'Arial', arial, sans-serif";
export const BaseTypeFontFamily100Bold = "'FS Elliot Web Bold', 'FS Elliot Web Regular', 'Arial', arial, sans-serif";
export const BaseTypeFontFamily100Heavy = "'FS Elliot Web Heavy', 'FS Elliot Web Bold', 'Arial', arial, sans-serif";
export const BaseTypeFontFamily300Semibold = "'Work Sans Semibold', 'Work Sans Regular', 'Arial', arial, sans-serif";
export const BaseTypeFontFamily300Bold = "'Work Sans Bold', 'Work Sans Semibold', 'Arial', arial, sans-serif";
export const BaseTypeLetterSpacing2 = "2px";
export const BaseTypeLetterSpacingNegative1 = "-1px";
export const BaseTypeLetterSpacingNegativeHalf = "-0.5px";
export const BaseTypeLetterSpacingDefault = "normal";
export const BaseTypeLetterSpacingHalf = "0.5px";

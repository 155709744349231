import {findKey} from "lodash"

export const roles = {
  MEMBER: ['member', 'prtcp', "MEMBER", 'prtcpnt'],
  EMPLOYER: ['employer', 'emplyr', "EMPLOYER"],
  ADVISOR: ['advisor', 'advsr', "ADVISOR", 'advisors'],
};

export function getRoleForString(searchValue) {
  return findKey(roles, role => role.includes(searchValue));
}

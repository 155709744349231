import * as React from "react";
import Error500 from "./Error500";
import {logError} from "../../services/logging";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      logError(window.location.href, error, errorInfo.componentStack);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Error500/>;
        }

        return this.props.children;
    }
}

import React, { useState } from "react";
import { useEffectOnce, useLocalStorage } from "react-use";
import useInterval from "@use-it/interval";
import { getToken } from "../../services/security";
import AuthRenderer from "./auth-renderer";
import {logInitialState} from "../../services/logging";

export default function AuthWrapper(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const [, setAccessToken] = useLocalStorage("accessToken", "", { raw: true });
  const [, setEnterpriseId] = useLocalStorage("enterpriseId", "", { raw: true });

  useEffectOnce(() => {
      fetchToken();
      logInitialState();
  });

  useInterval(fetchToken, 15 * 60 * 1000);

  async function fetchToken() {
    if(window.location.hostname === "localhost")
    {
      setIsValidToken(true);
      setIsLoading(false);
      return;
    }
    if (!props.getOwnSession) {
      setIsValidToken(true);
      setIsLoading(false);
      return;
    }

    try {
      const token = await getToken();
      setAccessToken(token.access_token);
      setEnterpriseId(token.businesscontext.affiliateId);
      setIsValidToken(true);
    } catch (e) {
      setIsValidToken(false);
    }
    setIsLoading(false);
  }

  return (
    <AuthRenderer isLoading={isLoading} isValidToken={isValidToken}>
      {props.children}
    </AuthRenderer>
  );
}

import axios from "axios";
import { securityTokenPath, tokenHost } from "../util/constants";

const url = tokenHost + securityTokenPath;

export async function getAccessToken() {
  const { data } = await axios.get(url, {
    withCredentials: true,
    params: {
      withCredentials: true
    }
  });
  return data;
}

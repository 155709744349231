import axios from "axios";
import { apiHost } from "../util/constants";
import "core-js/es/object/from-entries";

function buildUrl(path) {
  const formattedPath = path.charAt(0) === "/" ? path : `/${path}`;
  return `${apiHost}/rnq/services/participantwrapperservice${formattedPath}`;
}

export async function get(path, params = {}) {
  const apiToken = localStorage.getItem("accessToken");

  const { data } = await axios.get(buildUrl(path), {
    headers: {
      authorization: `Bearer ${apiToken}`
    },
    params
  });
  return data;
}

export async function post(path, postBody) {
  const apiToken = localStorage.getItem("accessToken");

  const { data } = await axios.post(buildUrl(path), postBody, {
    headers: {
      authorization: `Bearer ${apiToken}`
    }
  });
  return data;
}

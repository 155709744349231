import React from 'react'
import PlanNavigationMenu from "./plan-navigation-menu";
import Skeleton from "react-loading-skeleton";
import { usePath } from '../../util/use-path'

function PlanNavigation(props) {
  const { menus } = props;
  const path = usePath();

  if (!menus || menus.length === 0) {
    return <Skeleton count={8} width={100} />;
  }

  return (
    <React.Fragment>
        {menus.map(menu => <PlanNavigationMenu {...menu} key={menu.id} path={path} />)}
    </React.Fragment>
  );
}

export default PlanNavigation;

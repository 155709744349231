import qs from 'query-string'
import { getRoleForString } from '../contexts/session-context/roles'
import { jsfHost } from './constants'

export function getSessionFromParams() {
  const query = window.location.search
  const { planId, empId, role } = qs.parse(query)

  if (planId && empId) {
    return {
      planId,
      empId,
      role
      };
    }
    return {};
}


export function getFromSessionString() {
  function populateAdvisorSessionStorage() {
    if (window.location.host.toLowerCase().includes('advisor')) {
      const empId = window.document.getElementById('empId')
      const planId = window.document.getElementById('planId')
      if (empId && planId) {
        const sessionString = JSON.stringify({
          'empId': empId.textContent,
          'planId': planId.textContent,
          'userType': 'ADVISOR'
        })
        sessionStorage.setItem('session_string', sessionString)
      }
    }
  }

  populateAdvisorSessionStorage()
  const sessionString = sessionStorage.getItem('session_string')
  const session = JSON.parse(sessionString)

  let planId
  let empId
  let role

  if (session) {
    planId = session.planId
    empId = session.memberId || session.empId
      role = getRoleForString(session.userType) || getRoleForString(session.role);
    } else {
      return {};
    }


    if(session) {
        return {
            planId: planId,
            empId: empId,
            role: role
        };
    }

    return {};
}

export function getRoleFromSessionString() {
  const sessionString = sessionStorage.getItem("session_string");
  const session = JSON.parse(sessionString);

  if (session) {
    return getRoleForString(session.userType) || getRoleForString(session.role);
  } else {

  }
}

export function getFromSessionStringEmployer() {
  const sessionString = sessionStorage.getItem("session");
  const session = JSON.parse(sessionString);


  let planId;
  let empId;
  let role;
  if (session) {

    // Employer session is base64 encoded but advisor isn't
    try {
      planId = atob(session.session.planId);
      empId = atob(session.session.memberId);
      role = getRoleForString(session.session.userType);
    } catch(e) {
     return {};
    }

    return {
      planId: planId,
      empId: empId,
      role: role
    };
  }

  return {};
}

export function getFromSessionStringAdvisor() {
  const sessionString = sessionStorage.getItem('participantSessionParameter')
  let session
  if (sessionString != null) {
    session = JSON.parse(sessionString)
  }

  let planId
  let empId
  let role

  if (session) {
    planId = session.planId
    empId = session.memberId
    role = getRoleForString(session.userType)
  } else {
    return {};
  }


  if(session) {
    return {
      planId: planId,
      empId: empId,
      role: role
    };
  }

  return {};
}

export function logout() {
    window.location.assign(jsfHost + "member/accounts");
}

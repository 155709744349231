import React from "react";
import LoadingSpinner from "../loading-spinner/loading-spinner";
import { logoutPath } from "../../util/constants";

function AuthRenderer(props) {
  if (props.isLoading) {return <LoadingSpinner />;}
  if (props.isValidToken) {return <div>{props.children}</div>;}
  window.location.assign(logoutPath);
  return <LoadingSpinner />;
}

export default AuthRenderer;

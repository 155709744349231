import React, { useEffect, useState } from 'react'
import { getHeader } from '../../services/page-elements'
import './site-nav.scss'
import { headerHTML } from '../../util/header'

export default function SiteNav() {
  const [header, setHeader] = useState()

  useEffect(() => {
    fetchHeader()

    async function fetchHeader() {
      if (window.location.hostname === 'localhost') {
        setHeader(headerHTML)
        return
      }
      const data = await getHeader()
      setHeader(data)
    }
  }, [])

  if (window.location.pathname.includes('/landing')) {
    return null
  }

  if (!header) {
    return <div className='site-header-skeleton' />
  }

  return <div dangerouslySetInnerHTML={{ __html: header }} />
}

import axios from "axios";
import {apiHost, consumerKey} from "../util/constants";

const basePath = "/enterprise";
const buildPath = path => `${apiHost}${basePath}${path}`;

export async function get(path, paramsOverrides = {}) {
  const {data} = await axios.get(buildPath(path), {
    params: {apikey: consumerKey, ...paramsOverrides}
  });

  return data;
}

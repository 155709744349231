import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../components/loading-spinner/loading-spinner'
import { isEmpty } from 'lodash'
import {
  getFromSessionString,
  getFromSessionStringAdvisor,
  getFromSessionStringEmployer,
  getRoleFromSessionString,
  getSessionFromParams,
  logout
} from '../../util/session'
import { messageListJSON, planInfoJSON, planLogoJSON } from '../../util/header'
import { getPlanInfo, getPlanLogo } from '../../services/plans'
import { getMessages } from '../../services/plan-messages'

export const SessionContext = React.createContext()

export function SessionContextProvider(props) {
  const [session, setSession] = useState({})
  const [planInfo, setPlanInfo] = useState({})
  const [planLogo, setPlanLogo] = useState({})
  const [hasError, setHasError] = useState(false)
  const [planMessages, setPlanMessages] = useState([])


  useEffect(() => {
    const { planId, empId, role } = props
    if (planId && empId && role) {
      setSession({planId: planId, empId: empId, role: role})
      fetchInfo();
    } else {
      const { planId, empId, role } = getSession();
      if (planId && empId && role) {
        fetchInfo();
      }
    }

    function fetchInfo() {
      fetchPlanInfo()
      fetchPlanLogo()
      fetchMessages()
    }

    function getSession() {
      //Trust params first
      let sessionObject = getSessionFromParams()

      // Then participant session
      if (!sessionObject.empId && !sessionObject.planId) {

        sessionObject = getFromSessionString()
      }

      // Else get from employer session
      if (!sessionObject.empId && !sessionObject.planId) {
        sessionObject = getFromSessionStringEmployer()
      }

      // Else get from advisor session
      if (!sessionObject.empId && !sessionObject.planId) {
        sessionObject = getFromSessionStringAdvisor()
      }


      if (!sessionObject.planId || !sessionObject.empId) {
        setHasError(true)
        return {}
      }

      // Try to get role from sessionObject
      if (!sessionObject.role) {
        sessionObject.role = getRoleFromSessionString();
      }

      if (!sessionObject.role) {
        sessionObject.role = 'MEMBER';
      }
      setSessionStorage(sessionObject)
      setSession(sessionObject)

      return sessionObject;
    }

    async function fetchPlanInfo() {
      if (window.location.hostname === 'localhost') {
        setPlanInfo(planInfoJSON)
        return
      }
      if (session.planId && session.empId && session.role) {
        try {
          const data = await getPlanInfo(session.planId, session.empId, session.role)
          setPlanInfo(data)
        } catch (e) {
          setHasError(true)
        }
      }
    }

    async function fetchPlanLogo() {
      if (window.location.hostname === 'localhost') {
        setPlanLogo(planLogoJSON)
        return
      }
      if (session.planId && session.empId && session.role) {
        try {
          const data = await getPlanLogo(session.planId, session.empId, session.role)
          setPlanLogo(data)
        } catch (e) {

        }
      }
    }

    async function fetchMessages() {
      if (window.location.hostname === 'localhost') {
        setPlanMessages(messageListJSON)
        return;
      }
      if (session.planId && session.empId && session.role) {
        try {
          const messageData = await getMessages(session.planId, session.empId,
            session.role, ["GLOBAL", "PLAN_MESSAGE"])
          setPlanMessages(messageData)
        } catch (e) {

        }
      }
    }
  }, [session.empId, session.planId, session.role])

  function setSessionStorage(sessionObject) {
    if (!sessionStorage.getItem('session_string')) {
      sessionStorage.setItem('session_string', JSON.stringify(sessionObject))
    }
  }

  if (hasError) {
    logout()
    return <LoadingSpinner/>
  }

  if (isEmpty(session)) {
    return <LoadingSpinner/>
  }

  return (
    <SessionContext.Provider value={{ ...session, ...planInfo, planMessages, planLogo }}>
      {props.children}
    </SessionContext.Provider>
  )
}

import React, { useEffect } from 'react'

export const usePath = () => {
  const [path, setPath] = React.useState(window.location.pathname);
  const listenToState = () => {
    const winPath = window.location.pathname;
    setPath(winPath);
  };

  useEffect(() => {
    window.addEventListener("popstate", listenToState);
    window.addEventListener("pushstate", listenToState);
    return () => {
      window.removeEventListener("popstate", listenToState);
      window.removeEventListener("pushstate", listenToState);
    };
  }, []);
  return path;
};

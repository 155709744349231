import React from "react";

function Error500() {

  return (
    <section
      data-testid="error-500"
    >
      <div className="text-center">
        <h1>We’re sorry.</h1>
        <p>
          It looks like something went wrong on our end. We’ll work on getting
          that fixed.
        </p>
      </div>
    </section>
  );
}

export default Error500;

import React, { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { SessionContext } from '../../contexts/session-context/session-context'
import { getMenus } from '../../services/menus'
import { menuJSON } from '../../util/header'
import PlanMessageDisplay from '../plan-message/plan-message-display'
import './plan-header.scss'
import PlanNavigation from './plan-navigation'

function PlanHeader() {
  const {planId, empId, role, planName, participantName, contractId, planLogo} = useContext(
    SessionContext);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    if (planId && empId && role) {
      fetchMenus();
    }

    async function fetchMenus() {
      if (window.location.hostname === 'localhost') {
        setMenus(menuJSON)
        return
      }

      const menuData = await getMenus(planId, empId, role)
      setMenus(menuData)
    }
  }, [planId, empId, role]);

  if (window.location.pathname.includes('/landing')) {
    return null
  }

  const pathName = window.location.pathname

  return (
    <div>
      <div className="plan-header bg-white">
        <div className="container display">
          <Plan planName={planName} participantName={participantName}
                contractId={contractId} planLogo={planLogo}/>
        </div>

        <section
          className="nav-secondary-wrapper bg-white plan-navigation no-print">
          <nav className="container">
            <ul className="nav nav-secondary">
              <PlanNavigation menus={menus}/>
            </ul>
          </nav>
        </section>
      </div>
      <PlanMessageDisplay pathName={pathName}/>
    </div>
  )
}

function Plan(props) {
  const {planName, participantName, contractId, planLogo} = props;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-10">

          <p className="h1 plan-name">
            {planName || <Skeleton width={300}/>}
          </p>
          <p className="text-small account-identifier" data-hj-suppress>
            {participantName || <Skeleton width={100}/>}
          </p>
          <p className="text-small account-identifier">
            Contract Number: {contractId || <Skeleton width={60}/>}
          </p>
        </div>
        {planLogo.documentContent ?
          <div className="col-md-2 float-right custom-logo util-margin-top-10">
            <img className="custom-logo-image hidden-xs hidden-sm"
                 src={`data:image/png;base64,${planLogo.documentContent}`}
                 alt="" />
          </div> : null
        }
      </div>
    </React.Fragment>
  );
}

export default PlanHeader;

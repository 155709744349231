import './polyfills'
import React from 'react'
import ErrorBoundary from './components/error/error-boundary'
import AuthWrapper from './components/auth/auth-wrapper'
import { SessionContextProvider } from './contexts/session-context/session-context'
import SiteNav from './components/site-nav/site-nav'
import PlanHeader from './components/plan-header/plan-header'


export const Navigation = (props) => {
  const { getOwnSession, empId, planId, role } = props

  return (
    <ErrorBoundary>
      <AuthWrapper getOwnSession={getOwnSession}>
        <SessionContextProvider empId={empId} planId={planId} role={role}>
          <SiteNav/>
          <PlanHeader/>
        </SessionContextProvider>
      </AuthWrapper>
    </ErrorBoundary>
  )
}


import * as participantRepository from "../repositories/participant";
import { get as getSalesforce } from "../repositories/salesforce";

export async function getPlanInfo(planId, empId, role) {
  return participantRepository.get(
    `/plans/${planId}/employees/${empId}/`, {role}
  );
}

export async function getPlanLogo(planId, empId, role) {
  return participantRepository.get(
    `/plans/${planId}/employees/${empId}/logo`, {role}
  );
}

export async function getPlanConfig(planId, empId) {
  return getSalesforce(`/plans/${planId}/employees/${empId}/plan`);
}

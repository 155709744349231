import * as enterpriseRepository from "../repositories/enterprise";

export async function getHeader() {
  return enterpriseRepository.get("/theme/member/v3/header");
}

export async function getFooter() {
  return enterpriseRepository.get("/theme/member/v3/footer", {
    "contact-us": "CORPORATE"
  });
}

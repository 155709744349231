import classNames from 'classnames';
import React from 'react';
import PlanNavigationMenuItem, {
  removeNqParticipant
} from './plan-navigation-menu-item';
import { Link } from 'react-router-dom';
import { shouldRenderNewParticipantLink } from '../../util/should-render-new-participant-link';

export default function PlanNavigationMenu(props) {
  const { menuItems, id, name, heading, navigationLink, path } = props;
  const hasMenuItems = menuItems.length > 0;

  const isInPath = path.includes(name);

  const classes = classNames({
    dropdown: hasMenuItems,
    active: isInPath
  });

  function getUrl() {
    return new URL(navigationLink);
  }

  return (
    <li id={id} className={classes} data-testid={`menu-${id}`} key={id}>
      {(hasMenuItems || !shouldRenderNewParticipantLink(navigationLink)) && (
        <a
          id={`dropdown:_${name}`}
          name={name}
          data-toggle={hasMenuItems ? 'dropdown' : ''}
          data-testid={`menu-link-${id}`}
          aria-expanded='false'
          href={navigationLink || (hasMenuItems ? '#' : null)}
          className='dropdown-toggle'
        >
          {heading}
        </a>
      )}
      {!hasMenuItems && shouldRenderNewParticipantLink(navigationLink) && (
        <Link
          to={
            removeNqParticipant(getUrl().pathname, '/nq-participant', '') +
            getUrl().search
          }
          id={`Sub-menu:_${id}`}
          data-testid={`dropdown-item-${id}`}
          name={name}
        >
          {heading}
        </Link>
      )}
      <ul className='dropdown-menu'>
        {menuItems.map((item) => (
          <PlanNavigationMenuItem {...item} key={item.id} />
        ))}
      </ul>
    </li>
  );
}

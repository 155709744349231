import * as participantRepository from "../repositories/participant";

async function postLogMessage(error) {
  return participantRepository.post(
    `/log`, error
  );
}

export async function logInfo(message, stack) {
  return postLogMessage(buildLogMessage(level.INFO, message, stack))
}

export async function logError(message, stack) {
  return postLogMessage(buildLogMessage(level.ERROR, message, stack))
}

export async function logWarn(message, stack) {
  return postLogMessage(buildLogMessage(level.WARN, message, stack))
}

export async function logDebug(message, stack) {
  return postLogMessage(buildLogMessage(level.DEBUG, message, stack))
}

export async function logTrace(message, stack) {
  return postLogMessage(buildLogMessage(level.TRACE, message, stack))
}

function buildLogMessage(level, message, stack) {
  return {
    url: window.location.href,
    level: level,
    message: message,
    stack: stack
  }
}

export function logInitialState() {
  logPreviousUrl();
  logClientInfo();
}

function logPreviousUrl() {
  const previousUrl = document.referrer;
  logInfo(`Entered our app from url: ${previousUrl}`);
}

function logClientInfo() {
  const userAgent = `{userAgent: ${navigator.userAgent}}`;
  const appCodeName = `{appCodeName: ${navigator.appCodeName}}`;
  const appVersion = `{appVersion: ${navigator.appVersion}}`;
  const platform = `{platform: ${navigator.platform}}`;
  const language = `{language: ${navigator.language}}`;
  logInfo(`{Client details: {${userAgent},${appCodeName},${appVersion},${platform},${language}}`);
}

export const level = {
  INFO: "INFO",
  ERROR: "ERROR",
  DEBUG: "DEBUG",
  WARN: "WARN",
  TRACE: "TRACE",
};


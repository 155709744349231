function determineSecure05Host() {
  if (isEnv("pilot")) {
    return "https://secure05.pilot.";
  }
  return "https://secure05.";
}

function determineApiHost() {
  if (process.env.NODE_ENV === "test") {
    return "http://api.test.com";
  }
  if (isEnv("pilot")) {
    return "https://api.pilot.principal.com";
  }
  if (isEnv("local")) {
    return "/api";
  }
  return "https://api.principal.com";
}

function getConsumerKeyForApigee() {
  if (process.env.NODE_ENV === "test") {
    return "DMEQy9nPXTlao5VhwbTF0OGN70O8aMgf";
  }
  if (isEnv("pilot")) {
    return "DMEQy9nPXTlao5VhwbTF0OGN70O8aMgf";
  }
  if (isEnv("local")) {
    return "DMEQy9nPXTlao5VhwbTF0OGN70O8aMgf";
  }
  return "mKovvjdpNZsb8rCzPXUOfGJUma4ZUGAG";
}

function determineTokenHost() {
  if (process.env.NODE_ENV === "test") {
    return "http://api.test.com";
  }
  if (isEnv("pilot")) {
    return "https://api.pilot.principal.com";
  }
  if (isEnv("local")) {
    return "/token";
  }
  return "https://api.principal.com";
}

function determineJSFHost() {
  if (process.env.NODE_ENV === "test") {
    return "https://secure05.test.principal.com/";
  }
  if (isEnv("pilot")) {
    return "https://secure05.pilot.principal.com/";
  }
  if (isEnv("local")) {
    return "https://localhost/";
  }
  return "https://secure05.principal.com/";
}

function isEnv(env) {
  return window.location.host.indexOf(env) > -1;
}

export const secure05Host = determineSecure05Host();
export const consumerKey = getConsumerKeyForApigee();
export const apiHost = determineApiHost();
export const tokenHost = determineTokenHost();
export const jsfHost = determineJSFHost();
export const enterpriseApiKey = "RNQITRNQPARTICIP";
export const securityTokenPath =
  "/rnq/services/tokenservice/authorize/nq-participant";
export const logoutPath = jsfHost + "auth/corp/logout.jsp";


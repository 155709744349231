import {secure05Host} from "./constants";

export function interpolateMessage(message, contractId, planId, empId) {
  const planDocsLink = getPlanDocsLink(planId, empId);
  return message.replace(/\[PLAN_DOCS_LINK\]/g, planDocsLink);
}

function getPlanDocsLink(planId, empId) {
  const page = `principal.com/nq-participant/overview/plan-info-summary?planId=${planId}&empId=${empId}`;
  const link = `${secure05Host}${page}`;
  return `<a href="${link}">Plan documents</a>`;
}
